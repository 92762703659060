function copyToClipBoard(fieldId) {
    // Get text
    var txt = fieldId;
    // Create new element
    var el = document.createElement('textarea');
    // Set value (string to be copied)
    el.value = txt;
    // Set non-editable to avoid focus and move outside of view
    el.setAttribute('readonly', '');
    el.style = {
        position: 'absolute',
        left: '-9999px'
    };
    document.body.appendChild(el);
    // Select text inside element
    el.select();
    // Copy text to clipboard
    document.execCommand('copy');
    // Remove temporary element
    document.body.removeChild(el);
    alert('O link foi copiado para a área de transferência e possui validade de 3 dias.'); /* Alert the copied text */
}

function validaCPF(CPF) {

    if (CPF == '') {
        return true;
    }

    var erro = 0;
    var Soma;
    var Resto;
    var strCPF = CPF.replace(".", ""); //tira ponto
    strCPF = strCPF.replace(".", ""); //tira o segundo ponto
    strCPF = strCPF.replace("-", ""); //tira hífen
    Soma = 0;
    if (strCPF == "00000000000") erro = 1;
    for (i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(9, 10))) erro = 1;
    Soma = 0;
    for (i = 1; i <= 10; i++)
        Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;
    if ((Resto == 10) || (Resto == 11)) Resto = 0;
    if (Resto != parseInt(strCPF.substring(10, 11))) erro = 1;
    if (erro == 0) {
        return true;
    } else {
        return false;
    }
}

function validaCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj == '') return false;
    if (cnpj.length != 14)
        return false;
    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        return false;
    // Valida DVs
    tamanho = cnpj.length - 2
    numeros = cnpj.substring(0, tamanho);
    digitos = cnpj.substring(tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(0))
        return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
            pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    if (resultado != digitos.charAt(1))
        return false;
    return true;
}

function limpaInputRadio(className) {
    $(className).prop( "checked", false );
}

/**
 * Confirmação para quando tentar deletar um registro
 */
/*$(document).on('click', '.delete-record', function(e) {
    e.preventDefault();
    var link = $(this);
    var result = confirm('Deseja excluir este registro?');
    if (result) {
        location.href = link.attr('href');
    }
});*/

/**
 * Carrega as cidades de um estado
 */
$(document).on('change', '.load-cidades', function (e) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var pk = $('option:selected', this).val();

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { estado: pk },
        context: this,
        url: "load-cidades",
        success: function (result) {
            $(target).html(result);
        }
    });
});

/** Carrega as subregioes de uma cidade */
$(document).on('change', '.load-subregioes', function (e) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var pk = $('option:selected', this).val();

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { cidade: pk },
        context: this,
        url: "load-subregioes",
        success: function (result) {
            $(target).html(result);
        }
    });
});

/** Carrega as UBS's de uma subregiao */
$(document).on('change', '.load-ubs', function (e) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var pk = $('option:selected', this).val();

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { subregiao: pk },
        context: this,
        url: "load-ubs",
        success: function (result) {
            $(target).html(result);
            $('.selectpicker').selectpicker('refresh');
        }
    });
});

/** Carrega as UBS's de uma subregiao */
$('.load-ubs-selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    e.preventDefault();

    console.log($(this).selectpicker('val'));
    var target = '#' + $(this).attr('data-id-target');
    var pk = $(this).selectpicker('val');

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { subregiao: pk },
        context: this,
        url: "load-ubs",
        success: function (result) {
            $(target).html(result);
            $('.selectpicker').selectpicker('refresh');
        }
    });
});


/** Carrega equipamentos visita */
$(document).on('click', '#btnAddEquipamento', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.html('Carregando ...');
    $.ajax({
        type: "POST",
        context: this,
        url: "load-equipamentos",
        success: function (result) {
            $('#equipamentoContent').append(result);
            btn.html('Adicionar mais equipamentos');
        }
    });
});

/** Remove novo equipamento */
$(document).on('click', '.btnDelEquipamento', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.closest('.row').remove();
});

/** Carrega materiais visita */
$(document).on('click', '#btnAddMateriais', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.html('Carregando ...');
    $.ajax({
        type: "POST",
        context: this,
        url: "load-materiais",
        success: function (result) {
            $('#materiaisContent').append(result);
            btn.html('Adicionar mais materiais');
        }
    });
});

/** Remove novo equipamento */
$(document).on('click', '.btnDelMateriais', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.closest('.row').remove();
});

/** Carrega materiais de ventilacao na visita */
$(document).on('click', '#btnAddMaterial', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.html('Carregando ...');
    $.ajax({
        type: "POST",
        context: this,
        url: "load-materiais-paciente",
        success: function (result) {
            $('#materialContent').append(result);
            btn.html('Adicionar mais materiais');
        }
    });
});

/** Carrega materiais de ventilacao na NF */
$(document).on('click', '#btnAddMaterialNf', function (e) {
    e.preventDefault();
    var btn = $(this);
    btn.html('Carregando ...');
    $.ajax({
        type: "POST",
        context: this,
        data: { 'itens': $('#materialContent>.row').length },
        url: "load-materiais-nf",
        success: function (result) {
            $('#materialContent').append(result);
            $('#totalMateriais').val($('#materialContent>.row').length);
            btn.html('Adicionar mais materiais');
        }
    });
});

/** Carrega materiais de ventilacao na NF */
$(document).on('click', '.btnRemoverItemNf', function (e) {
    e.preventDefault();
    $(this).closest('.row').remove();
    if ($('#materialContent>.row').length == 0) {
        $.ajax({
            type: "POST",
            context: this,
            data: { 'itens': $('#materialContent>.row').length },
            url: "load-materiais-nf",
            success: function (result) {
                $('#materialContent').append(result);
                $('#totalMateriais').val($('#materialContent>.row').length);
                btn.html('Adicionar mais materiais');
            }
        });
    }
    $('#totalMateriais').val($('#materialContent>.row').length);
});

/** Carrega as cidades de uma região */
$(document).on('change', '.load-cidades-regiao', function (e) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var refresh = $(this).attr('data-refresh-target');
    var pk = $('option:selected', this).val();

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { regiao: pk },
        context: this,
        url: "load-cidades-regiao",
        success: function (result) {
            $(target).html(result);

            if (refresh == 'yes')
                $('.selectpicker').selectpicker('refresh');
        }
    });
});

/** Carrega as UBS's de uma subregiao */
$('.load-cidades-regiao-selectpicker').on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var refresh = $(this).attr('data-refresh-target');
    var pk = $(this).selectpicker('val');

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { regiao: pk },
        context: this,
        url: "load-cidades-regiao",
        success: function (result) {
            $(target).html(result);

            if (refresh == 'yes')
                $('.selectpicker').selectpicker('refresh');
        }
    });
});

/** Carrega as regiões de um estado */
$(document).on('change', '.load-regioes', function (e) {
    e.preventDefault();

    var target = '#' + $(this).attr('data-id-target');
    var pk = $('option:selected', this).val();

    $(target).html('<option>Carregando ...</option>');
    $.ajax({
        type: "POST",
        data: { estado: pk },
        context: this,
        url: "load-regioes",
        success: function (result) {
            result = result.split('%%');
            $(target).html(result[1]);
            if ($("#cidades").length) {
                $("#cidades").html(result[2]);
                $('.selectpicker').selectpicker('refresh');
            }
        }
    });
});

/**
 * Carrega as contratos de um contratante
 */
$(document).on('change', '.sourceContratos', function (e) {
    e.preventDefault();
    var target = $(".targetContratos");
    var pk = $('option:selected', this).val();
    target.html('<option>Carregando contratos...</option>');
    $.ajax({
        type: "POST",
        data: { contratante: pk },
        context: this,
        url: "load-contratos",
        success: function (result) {
            target.html(result);
        }
    });
});


/**
 * MASCARAS DE CAMPOS
 */
$(document).on("focus", ".maskHora", function () {
    $('.maskHora').mask('00:00', { reverse: true });
});
$(document).on("focus", ".maskDataHora", function () {
    $('.maskDataHora').mask('00/00/0000 00:00', { reverse: true });
});
$(document).on("focus", ".maskData", function () {
    $('.maskData').mask('00/00/0000', { reverse: true });
});
$(document).on("focus", ".maskCEP", function () {
    $('.maskCEP').mask('00000-000', { reverse: true });
});
$(document).on("focus", ".maskDecimal", function () {
    var mask = "0000,00";
    var maxlength = $(this).attr("maxlength");
    if (maxlength != "") {
        mask = mask.substr(maxlength * -1);
    }
    $(".maskDecimal").mask(mask, { reverse: true });
});
$(document).on("focus", ".maskValor", function () {
    var mask = '000.000,00';
    var maxlength = $(this).attr('maxlength');
    if (maxlength != '') {
        mask = mask.substr(maxlength * -1);
    }
    $('.maskValor').mask(mask, { reverse: true });
});
$(document).on("focus", ".maskCPF", function () {
    $('.maskCPF').mask('000.000.000-00', { reverse: true });
});
$(document).on("focus", ".maskCNPJ", function () {
    $('.maskCNPJ').mask('00.000.000/0000-00', { reverse: true });
});
$(document).on("focus", ".maskTelefone", function () {
    var TelefoneMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(99) 00000-0000' : '(99) 0000-00009';
    },
        spOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(TelefoneMaskBehavior.apply({}, arguments), options);
            }
        };
    $('.maskTelefone').mask(TelefoneMaskBehavior, spOptions);
});

/**
 * Autocomplete off
 */
$(document).on("focus", "input", function () {
    $(this).attr('autocomplete', 'off');
});

/**
 * Marca se o paciente tem CPF ou não
 */
$(document).on('change', '.sem_cpf', function () {
    var field = $(this).attr('data-cpf-field');
    console.log(field, $(this).is(':checked'));
    if ($(this).is(':checked')) {
        $(field).val('').attr('readonly', 'readonly');
        $(field).removeAttr('required');
    } else {
        $(field).removeAttr('readonly');
        $(field).attr('required', 'required');
    }
});

/**
 * Marca se o usuario é contratante
 */
$(document).on('change', '#tipoUser', function () {
    var tipo = $(this).val();
    console.log(tipo);
    if (parseInt(tipo) === 3) {
        $('#contratantesField').removeClass('hide');
        $('#contratantesField select').attr('required', 'required');
    } else {
        $('#contratantesField').addClass('hide');
        $('#contratantesField select').val('').removeAttr('required');
    }
});

/**
 * Validação do formulário
 */
$(function () {

    if ($('.selectpicker').length) {
        $('.selectpicker').selectpicker({
            selectedTextFormat: 'count > 5'
        });
    }

    $('.sidebar-menu').tree();
    $(document).on('click', '#btnSalvar,.btnSalvar', function (e) {
        e.preventDefault();
        var form = $(this).closest('form');
        if (form.smkValidate()) {
            var go = true;
            var msg = '';
            //Se existir CNPJ valida
            if ($('.maskCNPJ', form).length && !validaCNPJ($('#cnpj', form).val())) {
                go = false;
                $('#cnpj', form).focus().parent().addClass('has-feedback has-error').append('<span class="help-block smk-error-msg">CNPJ Inválido</span>');
            } else {
                $('#cnpj', form).parent().removeClass('has-feedback has-error');
                $('#cnpj', form).next('.smk-error-msg').remove();
            }
            //Se existir CPF valida
            if (!$('.sem_cpf').is(':checked') && $('.sem_cpf').length) {
                if ($('.maskCPF', form).length && !validaCPF($('#cpf', form).val())) {
                    go = false;
                    $('#cpf', form).focus().parent().addClass('has-feedback has-error').append('<span class="help-block smk-error-msg">CPF Inválido</span>');
                } else {
                    $('#cpf', form).parent().removeClass('has-feedback has-error');
                    $('#cpf', form).next('.smk-error-msg').remove();
                }
            }

            if ($('#responsavel_cpf').length) {
                if ($('.maskCPF', form).length && !validaCPF($('#responsavel_cpf', form).val())) {
                    go = false;
                    $('#responsavel_cpf', form).focus().parent().addClass('has-feedback has-error').append('<span class="help-block smk-error-msg">CPF Inválido</span>');
                } else {
                    $('#responsavel_cpf', form).parent().removeClass('has-feedback has-error');
                    $('#responsavel_cpf', form).next('.smk-error-msg').remove();
                }
            }
            if (go) {
                if ($('#password', form).length) {
                    if ($.smkEqualPass('#password', '#password_again')) {
                        form.submit();
                    }
                } else {
                    form.submit();
                }
            }
        }
    });

    $(document).on('click', '#btnPass', function (e) {
        e.preventDefault();
        if ($('#formPass').smkValidate()) {
            var pass1 = $('#changePass').val();
            var codigo = $('#codeUser').val();
            if ($.smkEqualPass('#changePass', '#changePass2')) {
                $.ajax({
                    type: "POST",
                    data: { pass: pass1, codigo: codigo },
                    url: "change-pass",
                    success: function (result) {
                        var res = result.split('|');
                        Swal.fire({
                            type: res[2],
                            title: res[0],
                            text: res[1],
                            showConfirmButton: false,
                            timer: 3000,
                            onClose: () => {
                                setTimeout(function () {
                                    $('#changePass,#changePass2').val('');
                                }, 500)
                            }
                        });
                    }
                });
            }
        }
    });

    $(document).on('click', '.delete-record', function (e) {
        e.preventDefault();
        var link = $(this);
        Swal.fire({
            title: 'Tem certeza que deseja excluir este registro?',
            text: "Esta ação não pode ser revertida!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, quero excluir!'
        }).then((result) => {
            if (result.value) {
                location.href = link.attr('href');
            }
        });
    });

    $(document).on('click', '.recupera-record', function (e) {
        e.preventDefault();
        var link = $(this);
        Swal.fire({
            title: 'Tem certeza que deseja recuperar este registro?',
            text: "",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim, quero recuperar!'
        }).then((result) => {
            if (result.value) {
                location.href = link.attr('href');
            }
        });
    });

    if ($('.textDisable').length) {
        $('.textDisable').editable({
            showbuttons: false,
            placement: 'left',
            inputclass: 'input-disabled',
            tpl: '<textarea readonly></textarea>'
        });
    }

    if ($('.upd-text').length) {
        $('.upd-text').editable({
            emptytext: '<img src="dist/img/clear.png">'
        });
    }

    if ($('.upd-date').length) {
        $('.upd-date').editable({
            emptytext: '<img src="dist/img/clear.png">',
            format: 'yyyy-mm-dd',
            viewformat: 'dd/mm/yyyy',
            datepicker: {
                weekStart: 0
            }
        });
    }

    if ($('.upd-select').length) {
        $('.upd-select').editable({
            emptytext: '<img src="dist/img/clear.png">',
            mode: 'popup'
        });
    }

    if ($('.upd-ativo').length) {
        $('.upd-ativo').editable({
            mode: 'popup',
            success: function (response, newValue) {
                if (newValue === 'N') {
                    $(this).removeClass('label-success').addClass('label-danger');
                } else {
                    $(this).removeClass('label-danger').addClass('label-success');
                }
            }
        });
    }

    $('.selectpicker').selectpicker({
        liveSearch: true,
        title: "Selecione",
        selectedTextFormat: "count",
        actionsBox: true,
        deselectAllText: 'Nenhum',
        selectAllText: 'Todos'
    });

    //Date picker;
    $('.datepickerForm').datepicker({
        autoclose: true,
        format: 'dd/mm/yyyy',
        language: 'pt-BR'
    })
});

/* EXCLUI REGISTROS */
$(document).on('click', '.btnDeleteRegistros', function (e) {
    e.preventDefault();
    Swal.fire({
        title: 'Tem certeza que deseja excluir este registro?',
        text: "Esta ação não pode ser revertida!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, quero excluir!'
    }).then((result) => {
        if (result.value) {
            var $btn = $(this).button('loading');
            var pk = $(this).attr('data-codigo');
            $.ajax({
                type: "POST",
                data: { codigo: pk },
                dataType: 'json',
                context: this,
                url: "delete-registros",
                success: function (result) {
                    if (result.status == 'success') {
                        $(result.tr).addClass('success');
                        $(result.tr).fadeOut('slow');
                    } else {
                        alert(result.message);
                    }
                    $btn.button('reset');
                }
            });
        }
    });
});

/* EXCLUI CONTRATANTE DO PACIENTE */
$(document).on('click', '.btnDeleteContratante', function (e) {
    e.preventDefault();
    Swal.fire({
        title: 'Tem certeza que deseja excluir este registro?',
        text: "Esta ação não pode ser revertida!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, quero excluir!'
    }).then((result) => {
        if (result.value) {
            var $btn = $(this).button('loading');
            var pk = $(this).attr('data-codigo');
            var action = $('#formContratantes').attr('data-action');
            $.ajax({
                type: "POST",
                data: { codigo: pk },
                dataType: 'json',
                context: this,
                url: "delete-registros",
                success: function (result) {
                    if (result.status == 'success') {
                        $(result.tr).addClass('success');
                        $(result.tr).fadeOut('slow', function () {
                            $('#formContratantes').attr('action', action).show();
                        });
                    } else {
                        alert(result.message);
                    }
                    $btn.button('reset');
                }
            });
        }
    });
});

$(document).on('change', '#selectAll', function (e) {
    e.preventDefault();
    if ($(this).is(':checked')) {
        $('.checkFile').prop('checked', true);
        $('.btn-gerarPdfs').removeClass('hide');
        $('.btn-gerarExcel').removeClass('hide');
    } else {
        $('.checkFile').prop('checked', false);
        $('.btn-gerarPdfs').addClass('hide');
        $('.btn-gerarExcel').addClass('hide');
    }
});

$(document).on('change', '.checkFile', function () {
    var totalCheck = $('.checkFile:checked').length;
    if (totalCheck > 0) {
        $('.btn-gerarPdfs').removeClass('hide');
        $('.btn-gerarExcel').removeClass('hide');
    } else {
        $('.btn-gerarPdfs').addClass('hide');
        $('.btn-gerarExcel').addClass('hide');
    }
});
$(document).on('click', '.btn-gerarPdfs', function (e) {
    e.stopPropagation();
    e.preventDefault();
    $('.btn-gerarPdfs').attr('disabled', 'disabled');
    var user = $(this).attr('data-user');
    var contratante = $(this).attr('data-contratante');
    var totalCheck = $('.checkFile:checked').length;
    var fracao = 100 / totalCheck;
    var percent = 0;
    var cont = 0;
    var arr = [];
    $('.progress').removeClass("hide");
    $('.progress-bar span').html("Processando aguarde... 0%");

    $('.checkFile:checked').each(function () {
        var visita = $(this).val();
        console.log(visita);
        setTimeout(function () {
            $.ajax({
                type: "POST",
                data: { visita: visita, user: user, contratante: contratante },
                url: "gera-pdfs",
                async: false,
                cache: false,
                success: function (result) {
                    var res = result.split('|');
                    if (res[1] === 'N') {
                        $('.progress-bar').removeClass('progress-bar-success');
                        $('.progress-bar').addClass('progress-bar-danger');
                        $('.results').append(res[2]);
                    } else {
                        arr.push(visita);
                    }
                    percent = percent + fracao;
                    cont++;
                    if (cont === totalCheck) {
                        $('.progress-bar').css("width", "100%");
                        $('.progress-bar span').html("100%");
                        if (arr.length > 0) {
                            console.log('Gerou: ' + arr.length + ' arquivos');
                            $('.progress').addClass("hide");
                            $('.progress-bar span').html("");
                            $('.checkFile').prop('checked', false);
                            $('#selectAll').prop('checked', false);
                            $('.btn-gerarExcel').addClass('hide');
                            $('.btn-gerarPdfs').addClass('hide');
                            location.href = 'zip-files?dir=' + res[2];
                            // console.log(result);
                        }
                        $('.btn-gerarPdfs').removeAttr('disabled');
                    } else {
                        $('.progress-bar span').html("Processando aguarde... " + percent.toFixed(0) + "%");
                    }
                }
            });

        }, 1000)
    })
});

$(document).on('click', '.btn-gerarExcel', function (e) {
    e.stopPropagation();
    e.preventDefault();
    $('.btn-gerarExcel').attr('disabled', 'disabled');
    var user = $(this).attr('data-user');
    var contratante = $(this).attr('data-contratante');
    $('.progress').removeClass("hide");
    $('.progress-bar span').html("Processando aguarde...");
    $('.progress-bar').css("width", "100%");
    $.ajax({
        type: "POST",
        data: $('#formChecksVisitas').serialize() + '&user=' + user + '&contratante=' + contratante + '&excel=S',
        url: "exportar-visitas",
        async: false,
        cache: false,
        success: function (result) {
            var res = result.split('|');
            if (res[1] === '1') {
                $('.progress').addClass("hide");
                $('.progress-bar span').html("");
                $('.checkFile').prop('checked', false);
                $('#selectAll').prop('checked', false);
                $('.btn-gerarExcel').addClass('hide');
                $('.btn-gerarPdfs').addClass('hide');
                location.href = res[2];
                //console.log(result);
                $('.btn-gerarExcel').removeAttr('disabled');
            } else {
                $('.progress-bar').removeClass('progress-bar-success');
                $('.progress-bar').addClass('progress-bar-danger');
                $('.results').append(res[2]);
            }
        }
    });
});

$('.acessos-modulo').change(function () {
    var modulo = $(this).attr('data-modulo-nome');
    var usuario = $(this).attr('data-usuario');
    var id = $(this).attr('data-id');
    var codigo = $(this).attr('data-codigo');

    if ((this).checked) {
        $(this).prop("checked", true);
        var valor = 'S';
    } else {
        $(this).prop("checked", false);
        var valor = 'N';
    }
    $.ajax({
        type: "POST",
        url: 'acessos-permissao',
        dataType: 'json',
        context: this,
        data: { codigo: codigo, valor: valor, usuario: usuario, id: id },
        success: function (response) {
            console.log(response, response.usuario, response.mensagem);
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: true,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })

            Toast.fire({
                icon: response.tipo,
                title: response.mensagem
            })
        }
    });
});

/*var integrando = false;
$.ajaxSetup({cache: false});

var source = 'THE SOURCE';

function start_task(visitas,contratante,user) {
    integrando = true;
    add_log('START');
    $('.progress').removeClass("hide");
    $('.progress-bar').css("width","3%");
    $('.progress-bar span').html("3%");
    $('.btn-gerarPdfs').attr('disabled', 'disabled');
    var filepath;
    filepath = 'ajax/gera-pdfs.php?visita='+visitas+'&contratante='+contratante+'&user='+user;

    source = new EventSource(filepath);

    //a message is received
    source.addEventListener('message' , function(e) {
        var result = JSON.parse( e.data );
        //add_log(result.message);
        $('.progress-bar').width(result.progress + "%");
        if (result.erros>0) {
            $('.progress-bar').removeClass('progress-bar-success');
            $('.progress-bar').addClass('progress-bar-danger');
            $('.progress-bar span').html(result.progress + "% ("+result.erros+" erros)");
        } else {
            $('.progress-bar span').html(result.progress + "%");
        }
        if(e.data.search('TERMINATE') != -1) {
            add_log('Finalizado!!!');
            source.close();
            $('.btn-gerarPdfs').removeAttr('disabled');
            integrando = false;
            $('.progress').addClass("hide");
            $('.progress-bar').css("width","0%");
            $('.progress-bar span').html("0%");
            $('.checkFile').prop('checked',false);
            $('#selectAll').prop('checked',false);
            $('.btn-gerarPdfs').addClass('hide');
            // location.href = 'zip-files?dir=temp-'+user;
        }
    });
    source.addEventListener('error' , function(e) {
        add_log('Erro encontrado!!!');
        source.close();
        $('.btn-gerarPdfs').removeAttr('disabled');
    });
}

function stop_task() 		{
    source.close();
    add_log('Interrompido!!!');
}

function add_log(message) 		{
    var r = $('.results');
    r.html(message);
}

$(document).on('click', '.btn-gerarPdfs', function(e) {
    e.preventDefault();
    var user = $(this).attr('data-user');
    var contratante = $(this).attr('data-contratante');
    var arr = [];

    $('.checkFile:checked').each(function(){
        var visita = $(this).val();
        arr.push(visita);
    });

    const visitas = arr;

    start_task(visitas,contratante,user);
});*/

/*
$(document).on('click', '.btn-gerarPdfs', function(e) {
    e.stopPropagation();
    e.preventDefault();
    $('.btn-gerarPdfs').prop('disabled',true);
    const user = $(this).attr('data-user');
    const contratante = $(this).attr('data-contratante');
    const totalCheck = $('.checkFile:checked').length;
    var fracao = 100/totalCheck;
    var percent = 0;
    var cont = 0;
    var arr = [];

    $('.progress').removeClass("hide");
    $('.progress-bar').css("width","3%");
    $('.progress-bar span').html("3%");

    $('.checkFile:checked').each(function(){
        var visita = $(this).val();
        arr.push(visita);
    });
    const visitas = arr;
    $.ajax({
        type: "POST",
        data: {visita: visitas, user:user, contratante:contratante},
        url: "gera-pdfs",
        success: function (result) {
            var res = result.split('|');
            if (res[1] === 'N') {
                $('.progress-bar').removeClass('progress-bar-success');
                $('.progress-bar').addClass('progress-bar-danger');
                $('.results').append(res[2]);
            }else{
                arr.push(visita);
            }
            percent = percent + fracao;
            cont++;
            if(cont === totalCheck){
                $('.progress-bar').css("width","100%");
                $('.progress-bar span').html("100%");
                if(arr.length > 0){
                   console.log('Gerou: ' + arr.length + ' arquivos');
                    $('.progress').addClass("hide");
                    $('.progress-bar').css("width","0%");
                    $('.progress-bar span').html("0%");
                    $('.checkFile').prop('checked',false);
                    $('#selectAll').prop('checked',false);
                    $('.btn-gerarPdfs').addClass('hide');
                    location.href = 'zip-files?dir='+res[2];
                }
                $('.btn-gerarPdfs').prop('disabled',false);
            }else{
                $('.progress-bar').css("width",percent.toFixed(2) + "%");
                $('.progress-bar span').html(percent.toFixed(2) + "%");
            }
        }
    });
});*/